import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { Lines } from 'components/shared/lines';
import PropTypes from 'prop-types';
import React from 'react';
import IconCheck from 'svg/check.inline.svg';
import { docs } from 'utils/urls';

import styles from './bulletproofs.module.scss';

const Bulletproofs = (props) => {
  const {
    title,
    description,
    bullets,
    cta: { link: ctaLink, text: ctaText },
    showLines,
  } = props;

  let lines = null;
  let cta = null;

  if (showLines) {
    lines = (
      <>
        <Lines imageNumber={2} className={styles.linesWrapperRight} />
        <Lines imageNumber={3} className={styles.linesWrapperLeft} />
      </>
    );
  }

  if (ctaLink && ctaText) {
    cta = (
      <div className={'d-flex justify-content-center'}>
        <Button className={styles.button} tag={'a'} href={ctaLink} theme={'additional'} size={'sm'}>
          {ctaText}
        </Button>
      </div>
    );
  }

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <div className={styles.header}>
          <Heading className={styles.title} tag={'h2'}>
            {title}
          </Heading>
          <p className={styles.description}>{description}</p>
        </div>

        <div className={'row justify-content-center'}>
          {bullets.map(({ node: { title, description, link } }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={'col-md-4 col-sm-12'} key={`features-${i}`}>
              <div className={styles.item}>
                <IconCheck className={styles.itemIcon} />
                <Heading className={styles.itemTitle} tag={'h3'} size={'md'}>
                  {title}
                </Heading>
                <p className={styles.itemDescription}>{description}</p>
                {link ? (
                  <a href={link} className={'link'}>
                    Read more
                  </a>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        {cta}
        {lines}
      </div>
    </section>
  );
};

Bulletproofs.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  cta: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  showLines: PropTypes.bool,
};

Bulletproofs.defaultProps = {
  description: undefined,
  showLines: false,
  cta: {},
};

export default Bulletproofs;
