import { Feedbacks } from 'components/blocks/feedbacks';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export const AlternativeFeedbacks = () => {
  // Pepe: I define this view because I was not able to use multiple staticQueries on the same component
  const {
    allStressTestsJson: { edges: DATA },
  } = useStaticQuery(graphql`
    query allQuery {
      allStressTestsJson {
        edges {
          node {
            text
            author {
              name
              userinfo
            }
          }
        }
      }
    }
  `);

  return <Feedbacks title={'Engineering teams use k6 to prepare for high traffic'} feedbacks={DATA} />;
};
