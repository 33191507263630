import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { ItemCard } from 'components/shared/item-card';
import React, { useRef, useState } from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Navigation, Pagination } from 'swiper/swiper.esm';

// keep the order intact
import 'swiper/swiper-bundle.css';
import styles from './feedbacks.module.scss';
import QuotesIcon from './images/quotes.inline.svg';
import ArrowIcon from './svg/arrow.inline.svg';
import './feedbacks.scss';

export const Feedbacks = ({ title, description, feedbacks }) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slideNext();
      setIsPrevDisabled(false);
    }
  };
  const goPrev = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
      setIsNextDisabled(false);
    }
  };
  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        {(title || description) && (
          <div className={styles.header}>
            <Heading className={styles.title} tag={'h2'}>
              {title}
            </Heading>
            {description && <p className={styles.description}>{description}</p>}
          </div>
        )}
        <div className={'row justify-content-center'}>
          <div className={'col-xl-10 col-12'}>
            <div className={styles.swiperWrapper}>
              <ReactIdSwiperCustom
                Swiper={Swiper}
                modules={[Navigation, Pagination]}
                containerClass={`swiper-container ${styles.itemsWrapper}`}
                ref={swiperRef}
                slidesPerView={1}
                slidesPerGroup={1}
                spaceBetween={25}
                loopFillGroupWithBlank
                breakpoints={{
                  767.98: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 25,
                  },
                }}
                pagination={{
                  el: '.swiper-pagination',
                  type: 'bullets',
                  clickable: true,
                }}
                on={{
                  reachBeginning: () => setIsPrevDisabled(true),
                  reachEnd: () => setIsNextDisabled(true),
                }}
              >
                {feedbacks.map(({ node: { text, author } }) => (
                  <div className={styles.itemWrapper}>
                    <div className={styles.itemIconWrapper}>
                      <QuotesIcon className={styles.itemIcon} />
                    </div>
                    <ItemCard as={'div'} label={styles.item} noBorderOnHover>
                      <div className={styles.itemInner}>
                        <p className={styles.itemText}>{text}</p>
                        <div className={styles.itemFooter}>
                          <div className={styles.itemAuthorDetails}>
                            <h3 className={styles.itemAuthorName}>{author.name}</h3>
                            <span className={styles.itemAuthorUsername}>{author.userinfo}</span>
                          </div>
                        </div>
                      </div>
                    </ItemCard>
                  </div>
                ))}
              </ReactIdSwiperCustom>
              <Button
                tag={'button'}
                type={'button'}
                shape={'round'}
                theme={'gradient-tertiary'}
                onClick={goNext}
                className={`${styles.sliderControl} ${isNextDisabled && styles.sliderControlHidden}`}
              >
                <ArrowIcon />
              </Button>
              <Button
                tag={'button'}
                type={'button'}
                shape={'round'}
                theme={'gradient-tertiary'}
                onClick={goPrev}
                className={`${styles.sliderControl} ${styles.sliderControlPrev} ${
                  isPrevDisabled && styles.sliderControlHidden
                }`}
              >
                <ArrowIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
